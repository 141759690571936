#main {
  min-height: calc(92vmin);
}

iframe {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

iframe {
  height: calc(100vh - 5vmin);
}

.iframe-container {
  width: 100%;
}

/* width */
#dashboard-viewer::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#dashboard-viewer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#dashboard-viewer::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#dashboard-viewer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cover > .loader {
  z-index: -9;
}
