@media screen and (min-width: 960px) {
  .dropdown {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    background: #222629;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 5vmin;
    font-size: calc(10px + 2vmin);
    width: 12%;
  }

  .dropbtn {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .dropbtn {
    background-color: #3498db;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 100%;
    float: left;
  }

  .dropbtn:hover,
  .dropbtn:focus {
    background-color: #2980b9;
  }

  .dropdown {
    display: inline-block;
    width: 100%;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown a:hover {
    background-color: #ddd;
  }

  .dropdown .dropbtn i {
    float: right;
    font-size: 1em;
  }
}
