.topnav {
  background-color: #252525;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 5vmin;
  font-size: 0.8em;
}

.topnav .logo {
  float: left;
  background: rgba(255, 255, 255, 0.2);
  width: 20%;
  height: 100%;
}

.topnav .logo img {
  margin: auto;
  margin-left: 10px;
  padding: 5px;
  max-width: 80%;
  max-height: 80%;
  display: block;
  filter: brightness(0) invert(1);
}

.topnav .menu {
  width: 80%;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 0 10px;
  height: 5vmin;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #00A6CE;
  color: white;
}

.topnav .menu a {
  padding: 5px 15px;
}

.accountButtons {
  float: right;
  display: flex;
}

.accountButtons span {
  float: left;
  color: #f2f2f2;
  text-align: center;
  height: 5vmin;
  padding: 0 5px;
}

@media screen and (min-width: 1800px) {
  .topnav .logo {
    width: 15%;
  }

  .topnav a {
    padding: 5px 15px;
  }

  .accountButtons span {
    padding: 5px 15px;
  }
}

@media screen and (min-width: 961px) and (max-width: 1880px) {
  .topnav,
  .topnav a {
    height: 5vmin;
  }
}

@media screen and (max-width: 960px) {
  .accountButtons span {
    height: 9vmin;
  }
}

@media screen and (max-width: 960px) {
  .topnav,
  .topnav a {
    position: unset;
    height: 12vmin;
  }

  .topSmallScreenMenu {
    display: none;
    background: #222629;
    float: left;
    position: absolute;
    top: 12vmin;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  .topSmallScreenMenu a {
    display: block;
    width: 100%;
  }

  .topnav .menu {
    width: 100%;
    float: left;
    margin-top: 11vmin;
  }

  .accountButtons {
    position: absolute;
    width: 100%;
    margin: 0;
    border-top: 1px solid #fff;
    border-bottom: 2vmin solid #4e5154;
  }

  .accountButtons a,
  .accountButtons span {
    width: 50%;
    height: 9vmin;
  }

  .accountButtons a {
    background-color: #ad1d40;
    border-radius: 5px;
    margin: 0;
  }

  .accountButtons a:hover {
    background-color: #d41040;
  }

  #userAccount:before {
    content: "Logged in as: ";
  }

  #userAccount {
    color: #ccc;
  }
}
