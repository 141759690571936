/* Sidebar */
#sidebar {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  background: #222629;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 5vmin;
  font-size: calc(10px + 2vmin);
}

#sidebar #logo {
  position: relative;
  margin: 10px auto;
  min-height: 48px;
  display: inline-grid;
  padding-bottom: 15px;
  border-bottom: 1px solid #999;
  width: 100%;
}

#sidebar #logo .image {
  margin: auto;
}

#sidebar #logo h1 {
  width: 100%;
  padding: 5px;
  color: #fff;
  font-weight: 250;
  line-height: 0.8em;
  text-align: center;
}

#sidebar #logo p {
  text-align: center;
  font-size: 0.6em;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.25em;
  margin: 0.5em 0 0 0;
}

/* Sidebar navigation */

#nav ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

#nav ul li {
  padding-left: 0;
}

#nav ul li a {
  display: block;
  padding: 0.2em 0.5em 0.2em 0.5em;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  outline: 0;
  border: 0;
  -moz-transition: none;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

#nav ul li a:hover {
  color: rgba(0, 0, 0, 0.7);
  background: #ccc;
}

#nav ul li a span {
  position: relative;
  display: block;
  font-size: 0.6em;
}

#nav ul li a span:before {
  color: #41484c;
  text-align: center;
  line-height: 1.75em;
  padding-right: 15px;
  width:30px;
}

#nav ul li a.active {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

#nav ul li a.active span:before {
  color: #8ebebc;
}

/* Sidebar bottom icons */

#sidebar .bottom {
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  padding: 1.5em 0 0.75em 0;
}

#sidebar .icons {
  font-size: 0.8em;
  text-align: center;
}

#sidebar .icons a {
  color: #41484c;
  -moz-transition: color 0.35s ease-in-out;
  -webkit-transition: color 0.35s ease-in-out;
  -ms-transition: color 0.35s ease-in-out;
  transition: color 0.35s ease-in-out;
}

#sidebar .icons a:hover {
  color: #fff;
}

/* Narrower */
#sidebarToggle {
  display: none;
}

@media screen and (min-width: 1800px) {
  #sidebar {
    width: 15%;
  }
}

@media screen and (min-width: 1320px) and (max-width: 1800px) {
  #sidebar {
    width: 20%;
  }
}

@media screen and (min-width: 961px) and (max-width: 1320px) {
  #sidebar {
    width: 20%;
  }

  #logo .image {
    position: relative;
    margin: 0;
  }

  #nav ul li a {
    font-size: 0.8em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  #nav ul li a span:before {
    left: 100%;
    margin-left: -1.25em;
    line-height: 2.25em;
  }
}

@media screen and (max-width: 960px) {
  #sidebar {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    -webkit-overflow-scrolling: touch;
    display: none;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    position: unset;
  }

  #sidebar .top {
    position: relative;
  }

  #sidebar .bottom {
    display: none;
  }

  #sidebar #logo {
    margin: 1.5em 1.25em 1.25em 1.25em;
  }

  #nav ul li a {
    padding: 0.5em 1.25em 0.5em 1.25em;
  }

  #sidebarToggle {
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: -moz-transform 0.5s ease;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    display: block;
    right: 10px;
    position: absolute;
    top: 0;
    width: 3.25em;
    z-index: 10001;
  }

  #sidebarToggle .toggle {
    text-decoration: none;
    margin: auto;
    width: 100%;
    height: 100%;
    outline: 0;
    border: 0;
  }

  #sidebarToggle .toggle:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    text-transform: none !important;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }

  #sidebarToggle .toggle:before {
    text-decoration: none;
    content: "\f0c9";
    color: #fff;
    font-size: 18px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0.35em;
    text-align: center;
    position: absolute;
    display: block;
    width: 3.25em;
    height: 2.25em;
    line-height: 2.25em;
    margin-top: 25%;
  }
}

@media screen and (max-width: 960px) {
}

.visible {
  display: block !important;
}
